import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public getData(){
    return this.httpClient.get('https://drink-backend.passcreator.com/latest');
  }

  constructor(private httpClient: HttpClient) { }
}
