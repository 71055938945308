import { Component, OnInit } from '@angular/core';
import {Soaker} from '../soaker';

@Component({
  selector: 'app-soaker-detail',
  templateUrl: './soaker-detail.component.html',
  styleUrls: ['./soaker-detail.component.scss']
})
export class SoakerDetailComponent implements OnInit {

  soaker: Soaker;

  constructor() { }

  ngOnInit(): void {
  }

}
