import {Component, OnInit} from '@angular/core';
import {SOAKERS} from '../mock-soakers';
import {ApiService} from '../api.service';

@Component({
    selector: 'app-soakers',
    templateUrl: './soakers.component.html',
    styleUrls: ['./soakers.component.scss']
})
export class SoakersComponent implements OnInit {

    soakers;
    interval;

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.apiService.getData().subscribe((data) => {
                this.soakers = data;
            });
        }, 5000);

        this.apiService.getData().subscribe((data) => {
            this.soakers = data;
        });
    }
}
